import React, { useEffect, useState } from 'react';
import {
 Button,
 Col, 
 Form,
 Input,
 InputNumber,
 Row,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import userService from '../../services/user';
import { toast } from 'react-toastify';
import { removeFromMenu } from '../../redux/slices/menu';
import { fetchUsers } from '../../redux/slices/user';
import { useTranslation } from 'react-i18next';
import { fetchClients } from '../../redux/slices/client';

export default function UserEditForm({ form, data, image }) {
 const { t } = useTranslation();
 const activeMenu = useSelector((state) => state?.menu?.activeMenu, shallowEqual);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const { uuid } = useParams();
 const [error, setError] = useState(null);
 const [loadingBtn, setLoadingBtn] = useState(false);

 const onFinish = (values) => { 
   setLoadingBtn(true);
   const body = {
     firstname: values.firstname,
     lastname: values.lastname,
     email: values.email,
     phone: values.phone,
     images: [image?.name],
   };
  // const nextUrl = data.role !== 'user' ? 'users' : 'users';
   const nextUrl =  'users';
   console.log("user.role",data.role)
   userService
     .update(uuid, body)
     .then(() => {
       toast.success(t('successfully.updated'));
       dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
       navigate(`/${nextUrl}`);
      //  if (data.role === 'user') {
         dispatch(fetchClients());
      //  } else {
      //    dispatch(fetchUsers({ role: data.role }));
      //  }
     })
     .catch((err) => {
       setError(err.response?.data?.params || {});
     })
     .finally(() => setLoadingBtn(false));
 };


 return (
   <Form
     form={form}
     layout='vertical'
     initialValues={{
       ...data,
     }}
     onFinish={onFinish}
     className='px-2'
   >
     <Row gutter={12}>
       <Col span={12}>
         <Form.Item
           label={t('firstname')}
           name='firstname'
           help={error?.firstname ? error.firstname[0] : null}
           validateStatus={error?.firstname ? 'error' : 'success'}
           rules={[{ required: true, message: t('required') }]}
         >
           <Input className='w-100' />
         </Form.Item>
       </Col>

       <Col span={12}>
         <Form.Item
           label={t('lastname')}
           name='lastname'
           help={error?.lastname ? error.lastname[0] : null}
           validateStatus={error?.lastname ? 'error' : 'success'}
           rules={[{ required: true, message: t('required') }]}
         >
           <Input className='w-100' />
         </Form.Item>
       </Col>

       <Col span={12} hidden={process.env.REACT_APP_IS_DEMO === 'true'}>
         <Form.Item
           label={t('phone')}
           name='phone'
           help={error?.phone ? error.phone[0] : null}
           validateStatus={error?.phone ? 'error' : 'success'}
           rules={[{ required: true, message: t('required') }]}
         >
           <InputNumber className='w-100' />
         </Form.Item>
       </Col>

       <Col span={12} hidden={process.env.REACT_APP_IS_DEMO === 'true'}>
         <Form.Item
           label={t('email')}
           name='email'
           help={error?.email ? error.email[0] : null}
           validateStatus={error?.email ? 'error' : 'success'}
           rules={[{ required: true, message: t('required') }]}
         >
           <Input type='email' className='w-100' />
         </Form.Item>
       </Col>

       <Button type='primary' htmlType='submit' loading={loadingBtn}>
         {t('save')}
       </Button>
     </Row>
   </Form>
 );
}