import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/user';
import sellerUserService from '../../services/seller/user';

const initialState = {
 loading: false,
 users: [],
 error: '',
 params: {
   page: 1,
   perPage: 10,
   role: 'user',
 },
 meta: {},
};

export const fetchUsers = createAsyncThunk('user/fetchUsers', async (params = {}) => {
 try {
   const response = await userService.getAll({ ...initialState.params, ...params });
   return response;
 } catch (error) {
   throw error;
 }
});

export const fetchSellerUsers = createAsyncThunk('user/fetchSellerUsers', async (params = {}) => {
 try {
   const response = await sellerUserService.shopUsers({ ...initialState.params, ...params });
   return response;
 } catch (error) {
   throw error;
 }
});

const userSlice = createSlice({
 name: 'user',
 initialState,
 reducers: {},
 extraReducers: (builder) => {
   // Fetch Users
   builder
     .addCase(fetchUsers.pending, (state) => {
       state.loading = true;
       state.error = '';
     })
     .addCase(fetchUsers.fulfilled, (state, action) => {
       const { payload } = action;
       state.loading = false;
       if (payload?.data) {
         state.users = payload.data;
         state.meta = payload.meta || {};
         state.params = {
           ...state.params,
           page: payload.meta?.current_page || 1,
           perPage: payload.meta?.per_page || 10
         };
       }
       state.error = '';
     })
     .addCase(fetchUsers.rejected, (state, action) => {
       state.loading = false;
       state.users = [];
       state.error = action.error.message || 'Failed to fetch users';
     });

   // Fetch Seller Users  
   builder
     .addCase(fetchSellerUsers.pending, (state) => {
       state.loading = true;
       state.error = '';
     })
     .addCase(fetchSellerUsers.fulfilled, (state, action) => {
       const { payload } = action;
       state.loading = false;
       if (payload?.data) {
         state.users = payload.data;
         state.meta = payload.meta || {};
         state.params = {
           ...state.params,
           page: payload.meta?.current_page || 1,
           perPage: payload.meta?.per_page || 10
         };
       }
       state.error = '';
     })
     .addCase(fetchSellerUsers.rejected, (state, action) => {
       state.loading = false;
       state.users = [];
       state.error = action.error.message || 'Failed to fetch seller users';
     });
 },
});

export default userSlice.reducer;