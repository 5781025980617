import React, { useState } from 'react';
import { Button, Form, Modal } from 'antd';
import userService from '../../services/user';
import { useDispatch } from 'react-redux';
import { fetchUsers } from '../../redux/slices/user';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { fetchClients } from '../../redux/slices/client';

export default function UserRoleModal({ data, handleCancel }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function changeRole(data) {
    setLoading(true);
    userService
      .updateRole(data)
      .then(() => {
        toast.success(t('successfully.deleted'));
        dispatch(fetchClients());
        dispatch(fetchUsers({ role: data.role }));
        // if (data.role === 'user') {

        // } else {

        // }
        handleCancel();
      })
      .finally(() => setLoading(false));
  }

  const onFinish = () => {
    changeRole(data);
  };

  return (
    <Modal
      visible={!!data}
      title={t('Delete')}
      onCancel={handleCancel}
      centered
      confirmLoading={loading}
      footer={[
        <Button
          key="elete"
          type="primary"
          danger
          onClick={() => form.submit()}
          loading={loading}
        >
          {t('Delete')}
        </Button>,
        <Button
          key="cancel"
          type="default"
          onClick={handleCancel}
        >
          {t('cancel')}
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        name="user-role"
        form={form}
        onFinish={onFinish}
        initialValues={data}
      >
        <div className="flex flex-col items-center gap-4 py-4">
          <span className="text-red-500 text-xl">⚠️</span>
          <p className="text-center font-medium text-gray-700">
            {t('Are you sure you want to delete this user?')}
          </p>
          <p className="text-center text-sm text-gray-500">
            {t('This action cannot be undone. All data associated with this user will be permanently removed.')}
          </p>
        </div>
      </Form>
    </Modal>
  );
}